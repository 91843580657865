import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { Events, Link, scrollSpy } from "react-scroll";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { PageWidgetBaseProps } from "../pageWidget.types";
import { WidgetOptions } from "./";

interface GoToTopProps extends PageWidgetBaseProps<WidgetOptions> {
    className: string;
    anchorLabel: string;
    scrollYTarget: number;
}

interface GoToTopState {
    disableWidget: boolean;
}

export class GoToTop extends React.PureComponent<GoToTopProps, GoToTopState> {
    constructor(props: GoToTopProps) {
        super(props);
        this.state = {
            disableWidget: true,
        };
    }

    public componentDidMount() {
        const { scrollYTarget } = this.props;
        this.setState({ disableWidget: !isClientLoggedIn() });
        // eslint-disable-next-line no-empty
        Events.scrollEvent.register("begin", () => {});
        // eslint-disable-next-line no-empty
        Events.scrollEvent.register("end", () => {});
        scrollSpy.update();
        const scrollTop = document.getElementsByClassName("go-to-top").item(0);
        // eslint-disable-next-line radix
        const targetYPosition = scrollYTarget ? scrollYTarget : 500;
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > targetYPosition && scrollTop) {
                scrollTop.classList.add("to-top-visible");
            } else if (scrollTop) {
                scrollTop.classList.remove("to-top-visible");
            }
        });
    }
    public componentWillUnmount() {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
        window.removeEventListener("scroll", () => 0);
    }

    public render(): JSX.Element | null {
        const { options, anchorLabel } = this.props;
        const lower = anchorLabel.trim().toLowerCase();
        const anchor = lower.split(" ").join("");
        const activeClass = "active";
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        return (
            <Link to={anchor} spy={true} className={"go-to-top"} activeClass={activeClass} smooth={true} offset={-100} duration={1700} delay={300} isDynamic={true} onSetActive={this.handleSetActive}>
                <FontAwesome name="caret-up" />
            </Link>
        );
    }
    // eslint-disable-next-line no-empty
    private handleSetActive = (to: any) => {};
}
