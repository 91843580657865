import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { GoToTop } from "./GoToTop";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    useStyled: boolean;
    anchorLabel: string;
    scrollYTarget: number;
}

const TARGETS = ["goToTop"];
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "goToTop-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetgoToTop, "goToTopWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetgoToTop, "goToTopWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetgoToTop, "general"),
                    properties: [
                        [
                            {
                                variable: "anchorLabel",
                                label: getI18nLocaleObject(namespaceList.widgetgoToTop, "anchorlabel"),
                                type: "text",
                            },
                            {
                                variable: "scrollYTarget",
                                label: getI18nLocaleObject(namespaceList.widgetgoToTop, "setScrollPosition"),
                                type: "text",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetgoToTop, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const goToTopWidget: PageWidgetSpec<WidgetOptions> = {
    id: "goToTop",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetgoToTop, "goToTopWidget"),
    description: getI18nLocaleObject(namespaceList.widgetgoToTop, "goToTopWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        useStyled: false,
        anchorLabel: "",
        scrollYTarget: 0,
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const { anchorLabel, scrollYTarget } = widget.options;
        // eslint-disable-next-line radix
        const scrollTarget = parseInt(scrollYTarget.toString());
        return <GoToTop className={className} options={widget.options} anchorLabel={anchorLabel} scrollYTarget={scrollTarget} />;
    },
};
